const DIAGNOSTIC_SUBCATEGORY_TRANSFERT = 'Transfert';
const DIAGNOSTIC_SUBCATEGORY_INDOOR = 'Déplacement intérieur';
const DIAGNOSTIC_SUBCATEGORY_OUTDOOR = 'Déplacement extérieur';
const DIAGNOSTIC_SUBCATEGORY_HOBBIES = 'Activités temps libre';

const DIAGNOSTIC_SUBCATEGORY_TOILET = 'Toilette';
const DIAGNOSTIC_SUBCATEGORY_INCONTINENCE = 'Incontinence';

const DIAGNOSTIC_SUBCATEGORY_DRESSING_TOP = 'Habillage haut';
const DIAGNOSTIC_SUBCATEGORY_DRESSING_BOTTOM = 'Habillage bas';

const DIAGNOSTIC_SUBCATEGORY_COOKING = 'Cuisine';
const DIAGNOSTIC_SUBCATEGORY_FOOD = 'Alimentation';

const DIAGNOSTIC_SUBCATEGORY_ALERT = 'Alerter';
const DIAGNOSTIC_SUBCATEGORY_FALL = 'Chute';

const DIAGNOSTIC_SUBCATEGORY_MEDICAL_CARE = 'Suivi du traitement';

export const DIAGNOSTIC_SUBCATEGORIES_MOVEMENTS = [
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je fais tout seul(e)',
            },
            {
                answerId: 'b',
                label: "Quelqu'un m'aide pour sortir de mon lit / fauteuil",
            },
            {
                answerId: 'c',
                label: 'Je ne fais pas tout seul(e)',
            },
        ],
        id: 1,
        label: DIAGNOSTIC_SUBCATEGORY_TRANSFERT,
        questionLabel: "Concernant la faculté à se lever, se coucher ou s'asseoir…",
    },
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je fais tout seul(e)',
            },
            {
                answerId: 'b',
                label: "J'ai besoin d'une canne ou d'un déambulateur ",
            },
            {
                answerId: 'c',
                label: 'Je ne me déplace pas du tout',
            },
        ],
        id: 2,
        label: DIAGNOSTIC_SUBCATEGORY_INDOOR,
        questionLabel: 'Concernant la mobilité dans le lieu de vie...',
    },
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je fais tout seul(e) sans aide technique et sans personne ',
            },
            {
                answerId: 'b',
                label: "J'ai besoin d'une canne ou d'un déambulateur ou d'une personne (trouble cognitif) ",
            },
            {
                answerId: 'c',
                label: 'Je ne sors pas du tout',
            },
        ],
        id: 3,
        label: DIAGNOSTIC_SUBCATEGORY_OUTDOOR,
        questionLabel: "Concernant la mobilité à l'extérieur de ce dernier ? ",
    },
    {
        answers: [
            {
                answerId: 'a',
                label: "Je m'occupe seul(e)",
            },
            {
                answerId: 'b',
                label: "J'ai du monde qui vient m'occuper de temps en temps",
            },
            {
                answerId: 'c',
                label: "J'ai quelqu'un à la maison quotidiennement",
            },
        ],
        id: 4,
        label: DIAGNOSTIC_SUBCATEGORY_HOBBIES,
        questionLabel: 'Je pratique, volontairement, certaines activités :',
    },
];

export const DIAGNOSTIC_SUBCATEGORIES_HYGENE = [
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je fais tout seul(e) sans aide',
            },
            {
                answerId: 'b',
                label: "Je fais avec une aide (Quelqu'un m'aide) ",
            },
            {
                answerId: 'c',
                label: "Je ne fais pas du tout (quelqu'un fait à ma place)",
            },
        ],
        id: 1,
        label: DIAGNOSTIC_SUBCATEGORY_TOILET,
        questionLabel: 'Au sujet de votre hygiène corporelle, de sa préparation à sa réalisation',
    },
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je suis continent(e)',
            },
            {
                answerId: 'b',
                label: 'Je suis incontinent(e) partiel (fuite)',
            },
            {
                answerId: 'c',
                label: 'je suis totalement incontinent(e)',
            },
        ],
        id: 2,
        label: DIAGNOSTIC_SUBCATEGORY_INCONTINENCE,
        questionLabel: "Assurance de l'élimination urinaire et fécale. ",
    },
];

export const DIAGNOSTIC_SUBCATEGORIES_DRESSING = [
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je fais tout seul(e)',
            },
            {
                answerId: 'b',
                label: "J'ai besoin d'une aide humaine",
            },
            {
                answerId: 'c',
                label: "Quelqu'un le fait à ma place",
            },
        ],
        id: 1,
        label: DIAGNOSTIC_SUBCATEGORY_DRESSING_TOP,
        questionLabel: 'Le choix des vêtements du haut, la recherche dans le lieu de rangement :',
    },
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je fais tout seul(e)',
            },
            {
                answerId: 'b',
                label: "J'ai besoin d'une aide humaine",
            },
            {
                answerId: 'c',
                label: "Quelqu'un le fait à ma place",
            },
        ],
        id: 2,
        label: DIAGNOSTIC_SUBCATEGORY_DRESSING_BOTTOM,
        questionLabel: 'Le choix des vêtements du bas, la recherche dans le lieu de rangement :',
    },
];

export const DIAGNOSTIC_SUBCATEGORIES_FOOD = [
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je les prépare seul(e)',
            },
            {
                answerId: 'b',
                label: "J'aide une personne à faire mes repas",
            },
            {
                answerId: 'c',
                label: "J'ai quelqu'un qui fait les repas pour moi",
            },
        ],
        id: 1,
        label: DIAGNOSTIC_SUBCATEGORY_COOKING,
        questionLabel: 'Pour préparer mes repas :',
    },
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je mange seul(e)',
            },
            {
                answerId: 'b',
                label: "J'ai besoin d'aide pour couper et ouvrir",
            },
            {
                answerId: 'c',
                label: "J'ai besoin d'une aide humaine pour manger",
            },
        ],
        id: 2,
        label: DIAGNOSTIC_SUBCATEGORY_FOOD,
        questionLabel: 'Pour la prise des repas :',
    },
];

export const DIAGNOSTIC_SUBCATEGORIES_RISKS = [
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je peux appeler seul(e) en cas de problème',
            },
            {
                answerId: 'b',
                label: "j'ai besoin d'un matériel adapté mais peux appeler seul(e)",
            },
            {
                answerId: 'c',
                label: 'Je ne peux pas alerter seul(e)',
            },
        ],
        id: 1,
        label: DIAGNOSTIC_SUBCATEGORY_ALERT,
        questionLabel: 'Utiliser un moyen de communication à distance dans le but d’alerter en cas de besoin.',
    },
    {
        answers: [
            {
                answerId: 'a',
                label: 'Je ne tombe jamais',
            },
            {
                answerId: 'b',
                label: 'Je tombe entre 1 et 3 fois par mois',
            },
            {
                answerId: 'c',
                label: 'Je tombe plus de 3 fois par mois',
            },
        ],
        id: 2,
        label: DIAGNOSTIC_SUBCATEGORY_FALL,
        questionLabel: 'Êtes-vous sujet aux chutes ?',
    },
];

export const DIAGNOSTIC_SUBCATEGORIES_MEDICAL_CARE = [
    {
        answers: [
            {
                answerId: 'a',
                label: 'je prépare et je prends mon traitement seul(e)',
            },
            {
                answerId: 'b',
                label: "Quelqu'un le prépare pour moi et je le prends seul(e)",
            },
            {
                answerId: 'c',
                label: "J'ai quelqu'un qui prépare et me fait prendre mon traitement",
            },
        ],
        id: 1,
        label: DIAGNOSTIC_SUBCATEGORY_MEDICAL_CARE,
        questionLabel: "Le respect d'une ordonnance et le suivi d'une prescription :",
    },
];
